<template>
  <v-container fluid>
    登録情報
  </v-container>
</template>

<script>
import firebase from "../../../plugins/firebase";

export default {
  name: "RegistrationInformation",
  created() {
    firebase.onAuth();
  }
}
</script>

<style scoped>

</style>